import React, { useEffect, useContext } from "react";
import { navigate } from "gatsby";
import { useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import { isBrowser } from "../../utils/general";
import "./index.css";
import { LanguageContext } from "../../lang";
import { TRY_AGAIN } from "./graphql/mutation";

const NotFound = () => {
  const {
    translations: { notFound },
  } = useContext(LanguageContext);
  const { text, tryAgainButton, callButton } = notFound;
  const [createRide, { loading }] = useMutation(TRY_AGAIN);
  let decodedJwtTokenId = "";
  useEffect(() => {
    let token = isBrowser() ? JSON.parse(localStorage.getItem("token")) : {};
    let sessionToken = isBrowser()
      ? JSON.parse(sessionStorage.getItem("token"))
      : {};
    if (token || sessionToken) {
      const { _id } = jwtDecode(token ? token : sessionToken);
      decodedJwtTokenId = _id;
    }
  }, []);
  const rideTryAgain = async () => {
    let rideId = isBrowser() ? localStorage.getItem("XXXX") : null;
    if (!rideId) return;
    try {
      const res = await createRide({
        variables: {
          id: rideId,
        },
      });

      console.log("Ride created", rideId);
      navigate("/ride-waiting", {
        state: { id: rideId },
      });
    } catch (error) {
      console.log("error: ", error);
      toast.error("Error While Creating Ride", {
        autoClose: 1200,
      });
    }
  };

  return (
    <section id="book-form">
      <section id="not-found-section">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-2 col-md-12 text-center not-found-img-bg p-4">
              <img
                className="img-fluid not-found-img"
                src={require("../../images/notfound/not-found-img.png")}
              />
            </div>

            <div className="col-lg-6 col-md-12 bg-white p-4">
              <p className="not-found-text p-3">{text}</p>
              <div className="not-found-btn-wrap mt-4 pl-3">
                <a onClick={() => rideTryAgain()} className="btn try-again-btn">
                  {tryAgainButton}
                </a>
                <a className="btn call-btn">{callButton}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default NotFound;
