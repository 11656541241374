import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import NotFound from '../components/NotFound'

const RideNotFound = () => (
	<Layout>
		<SEO title='Rides' />
		<NotFound />
	</Layout>
)

export default RideNotFound
